import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Skeleton, Select, Button, Row, Col, Modal } from "antd";

import usePropertiesWithSystems from "../../../../../api/hooks/usePropertiesWithSystems";
import useZonesByPropertyId from "../../../../../api/hooks/useZoneByPropertyId";
import useWindowSize from "../../../../../hooks/useWindowSize";
import ZoneCard from "./ZoneCard";
import ZoneCardColumns from "./ZoneCardColumns";
import ZoneCreate from "./ZoneCreate";

function ZoneManagementPropertyList() {
  const window = useWindowSize();
  const pageSize = 10;

  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [isNewZoneModalOpen, setIsNewZoneModalOpen] = useState(false);
  const [zoneIdForEdit, setZoneIdForEdit] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [editFormFloorFormReference, setEditFloorFormReference] = useState();

  const { data: properties } = usePropertiesWithSystems();

  const { data: zones, status: metadataQueryStatus } =
    useZonesByPropertyId(selectedPropertyId);

  const onChangeProperty = (value) => {
    if (!value) {
      setSelectedPropertyId("");
    } else {
      setSelectedPropertyId(value);
    }
  };

  const propertyOptions = [
    {
      value: "",
      label: "Select Property",
    },
  ];

  if (properties && properties) {
    properties.forEach((p) =>
      propertyOptions.push({ value: p.Id, label: p.Name }),
    );
  }

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {}, [
    selectedPropertyId,
    properties,
    isFormDirty,
    editFormFloorFormReference,
  ]);

  return (
    <div className="zone-management-property-list content-wrapper overflow-y-auto">
      <div className="flex md:items-center mb-2 flex-col-reverse md:flex-row">
        <div className="flex-1 mt-6 md:mt-1 flex lg:items-center flex-col lg:flex-row">
          {window?.width > 768 && (
            <h1 className="page-title mb-0 !text-triple-blue">Zones</h1>
          )}
          {window?.width <= 768 && (
            <h1 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
              Zones
            </h1>
          )}
        </div>
      </div>

      <Row className="justify-between my-6">
        <Col>
          <Select
            size="small"
            showSearch
            placeholder="All Properties"
            optionFilterProp="property"
            onChange={onChangeProperty}
            filterOption={filterSelect}
            className="mb-1 xl:mb-2 xl:w-64"
            defaultValue=""
            options={propertyOptions}
          />
        </Col>
        <Col>
          <Button
            className="py-2 px-4 rounded-full"
            type="primary"
            onClick={() => {
              setZoneIdForEdit(null);
              setIsNewZoneModalOpen(true);
            }}
          >
            New Zone
          </Button>
        </Col>
      </Row>

      <Modal
        className="property-management-floor-edit-modal"
        maskClosable={false}
        footer={null}
        open={isNewZoneModalOpen}
        keyboard={false}
        onCancel={() => {
          setZoneIdForEdit(null);
          setIsNewZoneModalOpen(false);
        }}
        destroyOnClose
        width={1800}
        bodyStyle={{ height: "75vh", overflowY: "auto", marginTop: "1.5rem" }}
      >
        <ZoneCreate
          propertyId={selectedPropertyId}
          setIsFormDirty={setIsFormDirty}
          setEditFloorFormReference={setEditFloorFormReference}
          zoneIdForEdit={zoneIdForEdit}
          setIsNewZoneModalOpen={setIsNewZoneModalOpen}
          setZoneIdForEdit={setZoneIdForEdit}
        />
      </Modal>

      {metadataQueryStatus === "error" && (
        <div className="p-6 text-white text-opacity-80 text-center">
          Select property to view zones
        </div>
      )}

      {metadataQueryStatus === "loading" &&
        Array.from({ length: pageSize }, (value, index) => index).map((el) => (
          <Skeleton
            active
            paragraph={{
              rows: 3,
            }}
            key={el}
          />
        ))}

      {metadataQueryStatus === "success" && zones?.length === 0 && (
        <div className="p-6 text-white text-opacity-80">
          <FrownOutlined className="text-xl mr-2" />
          We couldn`t find any matches
        </div>
      )}

      {metadataQueryStatus === "success" && zones?.length > 0 && (
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-6 lg:gap-x-0">
          <ZoneCardColumns />
          {selectedPropertyId &&
            zones.map((zone) => (
              <ZoneCard
                key={zone.ZoneName}
                zone={zone}
                setIsNewZoneModalOpen={setIsNewZoneModalOpen}
                setZoneIdForEdit={setZoneIdForEdit}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default ZoneManagementPropertyList;
