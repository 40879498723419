import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useZoneDelete() {
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationFn: (systemId) => {
      return axiosPrivate({
        method: "DELETE",
        url: `Zone/Delete/${systemId}`,
      });
    },
  });
}

export default useZoneDelete;
