import { Col, Divider, Row } from "antd";

import "../scss/ZoneManagement.scss";

function ZoneCardColumns() {
  return (
    <div role="none" className="zone-management border-triple-blue">
      <Row className="h-full justify-between">
        <Col
          className="self-center text-end"
          xs={{ span: 24 }}
          lg={{ span: 3 }}
        >
          <div className="my-4 mx-4">
            <div className="text-12 text-triple-white">Zone Name</div>
          </div>
        </Col>
        <Divider type="vertical" className="h-12 self-center" />
        <Col
          className="self-center text-center"
          xs={{ span: 24 }}
          lg={{ span: 3 }}
        >
          <div className="my-4 mx-4">
            <div className="text-12 text-triple-white">Zone System</div>
          </div>
        </Col>
        <Divider type="vertical" className="h-12 self-center" />
        <Col
          className="self-center text-center"
          xs={{ span: 24 }}
          lg={{ span: 3 }}
        >
          <div className="my-4 mx-4">
            <div className="text-12 text-triple-white">Device</div>
          </div>
        </Col>
        <Divider type="vertical" className="h-12 self-center" />
        <Col
          className="self-center text-center"
          xs={{ span: 24 }}
          lg={{ span: 3 }}
        >
          <div className="my-4 mx-4">
            <div className="text-12 text-triple-white">Zone Location</div>
          </div>
        </Col>
        <Divider type="vertical" className="h-12 self-center" />
        <Col
          className="self-center text-center"
          xs={{ span: 24 }}
          lg={{ span: 1 }}
        >
          <div className="my-4 mx-4">
            <div className="text-12 text-triple-white">Edit</div>
          </div>
        </Col>
        <Divider type="vertical" className="h-12 self-center" />
        <Col
          className="self-center text-start"
          xs={{ span: 24 }}
          lg={{ span: 2 }}
        >
          <div className="my-4 mx-4">
            <div className="text-12 text-triple-white">Delete</div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ZoneCardColumns;
