import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useZonesByPropertyId(
  propertyId,
  options,
  key = "getZonesByPropertyId",
) {
  const axiosPrivate = useAxiosPrivate();

  const getZonesByPropertyId = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: `/Zone/GetZonesForProperty/${propertyId}`,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, propertyId], () => getZonesByPropertyId(propertyId), {
    ...options,
  });
}

export default useZonesByPropertyId;
