function ZonesManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-33 -25 150 150"
      width="109.334"
      height="113.333"
    >
      <path
        fillRule="evenodd"
        fill="#757782"
        d="M81.798 84.761H.338V3.84h23.125v6.317H7.775v68.237h66.691V61.656h7.332l.059 23.105h-.059zM63.857 61.495c-2.731 3.064-5.724 5.89-8.75 8.984-1.771-1.669-3.393-3.089-4.902-4.616-6.505-6.605-12.08-13.897-15.88-22.415-2.926-6.562-4.597-13.406-3.799-20.65.677-6.215 3.488-11.385 8.135-15.564 4.519-4.071 9.932-6.177 15.921-6.32 5.97-.143 11.391 1.873 16.063 5.664 5.006 4.063 7.886 9.351 8.875 15.699.952 6.109-.382 11.924-2.378 17.609-2.859 8.15-7.59 15.2-13.285 21.609zM55.085 16.72c-4.298-.332-9.028 3.153-9.129 9.087-.078 4.795 4.282 9.023 9.08 8.944 5.125-.086 9.037-4.011 9.003-9.037-.037-5.191-3.869-9.042-8.954-8.994z"
      />
    </svg>
  );
}

export default ZonesManagementIcon;
