import { Button, Modal } from "antd";
import PropTypes from "prop-types";

function DeleteWarning({ onDeleteClick, onCancelClick, isAlertDeleteOpen }) {
  return (
    <Modal
      className="alert-unsaved-changes"
      maskClosable={false}
      closable={false}
      zIndex={9999}
      footer={
        <>
          <Button size="small" onClick={onDeleteClick}>
            Delete
          </Button>
          <Button size="small" onClick={onCancelClick}>
            Cancel
          </Button>
        </>
      }
      open={isAlertDeleteOpen}
      keyboard={false}
      destroyOnClose
    >
      <div className="text-center">
        <h4 className="uppercase">
          Are you sure you want to delete this item?
        </h4>
        <p>
          Click &quot;Cancel&quot; to discard changes or click
          &quot;DELETE&quot; to commit the changes.
        </p>
      </div>
    </Modal>
  );
}

DeleteWarning.propTypes = {
  onDeleteClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isAlertDeleteOpen: PropTypes.bool,
};

DeleteWarning.defaultProps = {
  onDeleteClick: () => {},
  onCancelClick: () => {},
  isAlertDeleteOpen: false,
};

export default DeleteWarning;
