import { useEffect, useRef, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input } from "antd";
import PropTypes from "prop-types";

import useCreateZone from "../../../../../api/hooks/useCreateZone";
import useSystemsByPropertyId from "../../../../../api/hooks/useSystemsByPropertyId";
import { updateTransferList } from "../helperFuncs/helperFunctions";
import useHandlePropertyZoneUpdate from "../hooks/useHandlePropertyZoneUpdate";
import ZoneSystems from "./ZoneSystems";
import "../scss/ZoneEdit.scss";

function ZoneCreate({
  propertyId,
  setIsFormDirty,
  setEditFloorFormReference,
  zoneIdForEdit,
  setIsNewZoneModalOpen,
  setZoneIdForEdit,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [editFormFloor] = Form.useForm();
  const initialFormValues = useRef({});
  const queryClient = useQueryClient();

  const { data } = useSystemsByPropertyId(propertyId, {
    enabled: true,
  });
  // Mutation Fns
  const createZoneMutation = useCreateZone();

  useEffect(() => {
    if (data) {
      if (zoneIdForEdit) {
        initialFormValues.current = {
          Systems: {
            AssignedSystems: data.AssignedSystems.filter((s) =>
              s.Devices.some((d) => d.ZoneUniqueId === zoneIdForEdit),
            ).map((s) => ({
              ...s,
              Devices: s.Devices.filter(
                (d) => d.ZoneUniqueId === zoneIdForEdit,
              ), // Keep only the matching devices
            })),
            UnassignedSystems: data.UnassignedSystems.filter((s) =>
              s.Devices.some((d) => d.ZoneUniqueId === null),
            ).map((s) => ({
              ...s,
              Devices: s.Devices.filter((d) => d.ZoneUniqueId === null), // Keep only the unassigned devices
            })),
          },
        };

        editFormFloor.setFieldsValue(initialFormValues.current);
      } else {
        initialFormValues.current = {
          Systems: {
            AssignedSystems: data.AssignedSystems,
            UnassignedSystems: data.UnassignedSystems,
          },
        };
        editFormFloor.setFieldsValue(initialFormValues.current);
      }
    }
  }, [editFormFloor, propertyId, data, zoneIdForEdit]);

  useEffect(() => {
    setEditFloorFormReference(editFormFloor);
  }, [editFormFloor, setEditFloorFormReference]);

  const handleValuesCahnges = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
    setDisableSaveChanges(false);
  };
  const floorSystemsRef = useRef();
  const HandleSubmit = async () => {
    setIsLoading(true);
    const formValues = editFormFloor.getFieldsValue();
    const { createZoneResponse } = await useHandlePropertyZoneUpdate(
      formValues,
      createZoneMutation,
      setIsFormDirty,
      setIsLoading,
    );

    const updated = updateTransferList(createZoneResponse);
    editFormFloor.setFieldsValue({
      ZoneName: createZoneResponse?.data?.ZoneName ?? null,
      Systems: {
        AssignedSystems: updated.assignedSystems,
        UnassignedSystems: updated.unassignedSystems,
      },
    });

    floorSystemsRef.current.setDataSource(updated.allSystems);
    floorSystemsRef.current.setExpandedRowKeys(updated.allRowKeys);
    floorSystemsRef.current.setTargetKeys(updated.flattenTargetKeys);
    floorSystemsRef.current.setLeftTableData(updated.assignedSystems);
    floorSystemsRef.current.setRightTableData(updated.unassignedSystems);

    queryClient.invalidateQueries({ queryKey: ["getZonesByPropertyId"] });

    setDisableSaveChanges(true);
    setZoneIdForEdit(null);
    setIsNewZoneModalOpen(false);
  };

  return (
    <div className="content-wrapper mx-2">
      <Form
        form={editFormFloor}
        name="floor-management-edit-floor"
        layout="vertical"
        className="w-full"
        initialValues={{
          Name: initialFormValues.current.Name,
          Systems: initialFormValues.current.Systems,
        }}
        onFinish={HandleSubmit}
        onValuesChange={handleValuesCahnges}
      >
        <div className="flex items-center justify-center mb-0">
          <h1 className="page-title">
            {zoneIdForEdit ? `Edit Zone: ${zoneIdForEdit}` : "Create Zone"}
          </h1>
        </div>
        <Form.Item name={["Systems", "AssignedSystems"]} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={["Systems", "UnassignedSystems"]} hidden>
          <Input />
        </Form.Item>
        {propertyId && (
          <ZoneSystems
            editFloorFormReference={editFormFloor}
            propertyId={propertyId}
            setDisableSaveChanges={setDisableSaveChanges}
            setIsFormDirty={setIsFormDirty}
            floorSystemsRef={floorSystemsRef}
            zoneIdForEdit={zoneIdForEdit}
          />
        )}

        <Button
          type="primary"
          htmlType="submit"
          size="medium"
          className="custom-property-save-btn"
          loading={isLoading}
          disabled={disableSaveChanges}
        >
          Save
        </Button>
      </Form>
    </div>
  );
}

ZoneCreate.propTypes = {
  propertyId: PropTypes.number.isRequired,
  setIsFormDirty: PropTypes.func,
  setEditFloorFormReference: PropTypes.func,
  zoneIdForEdit: PropTypes.any,
  setIsNewZoneModalOpen: PropTypes.func,
  setZoneIdForEdit: PropTypes.func,
};

ZoneCreate.defaultProps = {
  setIsFormDirty: () => {},
  setEditFloorFormReference: () => {},
  zoneIdForEdit: null,
  setIsNewZoneModalOpen: () => {},
  setZoneIdForEdit: () => {},
};

export default ZoneCreate;
