import { useState } from "react";

import { Col, Divider, Row } from "antd";
import PropTypes from "prop-types";

import useZoneDelete from "../../../../../api/hooks/useZoneDelete";
import DeleteWarning from "../../../../Shared/DeleteWarning";

import "../scss/ZoneManagement.scss";

function ZoneCard({ zone, setIsNewZoneModalOpen, setZoneIdForEdit }) {
  const [isAlertDeleteOpen, setAlertDeleteOpen] = useState(false);

  const useZoneDeleteMutation = useZoneDelete();

  const handleDeleteZone = () => {
    setAlertDeleteOpen(true);
  };

  return (
    <>
      <div
        role="none"
        className="bg-triple-main-blue shadow-md overflow-hidden"
      >
        <Row className="h-full justify-between">
          {zone?.ZoneName ? (
            <Col
              className="self-center text-end"
              xs={{ span: 24 }}
              lg={{ span: 3 }}
            >
              <div className="my-4 mx-4">
                <div className="text-12 text-triple-white-new">
                  {zone?.ZoneName}
                </div>
              </div>
            </Col>
          ) : (
            <Col
              className="self-center text-end"
              xs={{ span: 24 }}
              lg={{ span: 3 }}
            >
              <div className="my-4 mx-4">
                <div className="text-12 text-triple-white-new">
                  Zone Name is not present
                </div>
              </div>
            </Col>
          )}
          <Divider type="vertical" className="h-6 self-center" />
          <Col
            className="self-center text-center"
            xs={{ span: 24 }}
            lg={{ span: 3 }}
          >
            <div className="my-4 mx-4">
              <div className="text-12 text-triple-white-new">
                {zone?.ZoneSystem}
              </div>
            </div>
          </Col>
          <Divider type="vertical" className="h-6 self-center" />
          <Col
            className="self-center text-center"
            xs={{ span: 24 }}
            lg={{ span: 3 }}
          >
            <div className="my-4 mx-4">
              <div className="text-12 text-triple-white-new">
                {zone?.SystemDevice}
              </div>
            </div>
          </Col>
          {zone?.Location ? (
            <>
              <Divider type="vertical" className="h-6 self-center" />
              <Col
                className="self-center text-center"
                xs={{ span: 24 }}
                lg={{ span: 3 }}
              >
                <div className="my-4 mx-4">
                  <div className="text-12 text-triple-white-new">
                    {zone?.Location}
                  </div>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Divider type="vertical" className="h-6 self-center" />
              <Col
                className="self-center text-center"
                xs={{ span: 24 }}
                lg={{ span: 3 }}
              >
                <div className="my-4 mx-4">
                  <div className="text-12 text-triple-white-new">
                    Zone Location is not present
                  </div>
                </div>
              </Col>
            </>
          )}
          <Divider type="vertical" className="h-6 self-center" />
          <Col
            className="self-center text-center cursor-pointer"
            xs={{ span: 24 }}
            lg={{ span: 1 }}
            onClick={() => {
              setZoneIdForEdit(zone.ZoneName);
              setIsNewZoneModalOpen(true);
            }}
          >
            <div className="my-4 mx-4">
              <div className="text-12 text-triple-white-new">Edit</div>
            </div>
          </Col>
          <Divider type="vertical" className="h-6 self-center" />
          <Col
            className="self-center text-start cursor-pointer"
            xs={{ span: 24 }}
            lg={{ span: 2 }}
            onClick={handleDeleteZone}
          >
            <div className="my-4 mx-4">
              <div className="text-12 text-triple-white-new">Delete</div>
            </div>
          </Col>
        </Row>
      </div>
      <DeleteWarning
        onDeleteClick={() => {
          setAlertDeleteOpen(false);
          useZoneDeleteMutation.mutateAsync(zone?.SysUniqueId);
        }}
        onCancelClick={() => {
          setAlertDeleteOpen(false);
        }}
        isAlertDeleteOpen={isAlertDeleteOpen}
      />
    </>
  );
}

ZoneCard.propTypes = {
  zone: PropTypes.object,
  setIsNewZoneModalOpen: PropTypes.any,
  setZoneIdForEdit: PropTypes.any,
};

ZoneCard.defaultProps = {
  zone: {},
  setIsNewZoneModalOpen: () => {},
  setZoneIdForEdit: () => {},
};
export default ZoneCard;
